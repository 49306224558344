<template>
  <menu-principal>
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <titulo-pagina>{{ tituloPagina }}</titulo-pagina>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" v-if="!permissaoPagina">
          <bloco-erro>Acesso Negado</bloco-erro>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" v-if="permissaoPagina && !pacoteEncontrado">
          <bloco-erro>Pacote não Encontrado</bloco-erro>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" v-if="permissaoPagina && pacoteEncontrado && !permissaoPacote">
          <bloco-erro>Pacote não pode ser editado</bloco-erro>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" v-if="permissaoPagina && pacoteEncontrado && permissaoPacote">
          <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                      dense
                      v-model.trim="formulario.nome"
                      label="Nome do Pacote"
                      :error-messages="erros.nome"
                      prepend-inner-icon="mdi-package-variant"
                      outlined
                      counter
                      maxlength="100"
                      :append-icon="(!!erros.nome && iconeErro) || ''"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                      dense
                      v-model.trim="formulario.porcentagem"
                      label="Porcentagem do Pacote"
                      :error-messages="erros.porcentagem"
                      prepend-inner-icon="mdi-sale"
                      v-money="formulario.porcentagem !== null && configMonetario"
                      :append-icon="(!!erros.porcentagem && iconeErro) || ''"
                      outlined
                      v-mask="'##,##'"
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <v-select
                      dense
                      v-model="formulario.habilitado"
                      label="Habilitação do Pacote"
                      :error-messages="erros.habilitado"
                      :items="itensSelect.habilitacao"
                      item-text="label"
                      item-value="valor"
                      prepend-inner-icon="mdi-list-status"
                      outlined
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                      dense
                      v-model.trim="formulario.parcelasGarantia"
                      label="Parcelas garantidas"
                      :error-messages="erros.parcelasGarantia"
                      prepend-inner-icon="mdi-sale"
                      :append-icon="(!!erros.parcelasGarantia && iconeErro) || ''"
                      outlined
                      v-mask="'##'"
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                      dense
                      v-model.trim="formulario.parcelasReforma"
                      label="Parcelas de reforma"
                      :error-messages="erros.parcelasReforma"
                      prepend-inner-icon="mdi-sale"
                      :append-icon="(!!erros.parcelasReforma && iconeErro) || ''"
                      outlined
                      v-mask="'##'"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-select
                      dense
                      v-model="formulario.tipoPagamento"
                      label="Método de Pagamento"
                      :error-messages="erros.tipoPagamento"
                      :items="itensSelect.tipoPagamento"
                      item-text="label"
                      item-value="valor"
                      prepend-inner-icon="mdi-credit-card-outline"
                      outlined
                  />
                </v-col>

                <v-col cols="12" md="6" v-if="formulario.tipoPagamento === 'cartao'">
                  <v-text-field
                      dense
                      v-model.trim="formulario.quantidadeParcelas"
                      label="Quantidade de Parcelas"
                      :error-messages="erros.quantidadeParcelas"
                      prepend-inner-icon="mdi-sale"
                      :append-icon="(!!erros.quantidadeParcelas && iconeErro) || ''"
                      outlined
                      v-mask="'##'"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <div class="mt-4 ml-2">
                    <span class="font-weight-bold font-size-16">Dados de comissão</span>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                      dense
                      v-model="formulario.comissaoTaxaSetup"
                      label="Comissão da taxa setup"
                      :error-messages="erros.comissaoTaxaSetup"
                      prepend-inner-icon="mdi-sale"
                      v-money="formulario.comissaoTaxaSetup !== null && configMonetario"
                      :append-icon="(!!erros.comissaoTaxaSetup && iconeErro) || ''"
                      outlined
                      v-mask="'##,##'"
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                      dense
                      v-model="formulario.comissaoPacote"
                      label="Comissão do pacote"
                      :error-messages="erros.comissaoPacote"
                      prepend-inner-icon="mdi-sale"
                      v-money="formulario.comissaoPacote !== null && configMonetario"
                      :append-icon="(!!erros.comissaoPacote && iconeErro) || ''"
                      outlined
                      v-mask="'##,##'"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <div class="ml-2">
                    <span class="font-weight-bold font-size-16">Valores de Taxa Setup</span>
                  </div>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="3">
                  <v-text-field
                      dense
                      v-model="taxaSetup"
                      label="Adicionar taxa setup"
                      prefix="R$"
                      outlined
                      hide-details
                      @keyup.enter="adicionarTaxaSetup"
                      type="number"
                  />
                </v-col>
                <v-col cols="12">
                  <v-chip
                      class="mr-1"
                      v-for="(taxa, idx) in taxasSetup"
                      :key="'taxa-' + idx"
                      @click:close="removerTaxaSetup(taxa)"
                      close>
                    R$ {{ String(parseFloat(taxa.valor).toFixed(2)).replace('.', ',') }}
                  </v-chip>
                </v-col>
              </v-row>


              <v-row>
                <v-col cols="12" align="right">
                  <div class="mt-5">
                    <v-btn
                        :block="blockBtns"
                        :class="{'mr-4': !blockBtns,'mb-4': blockBtns}"
                        dark
                        depressed
                        color="orange"
                        @click="confirmarSaida()"
                    >Ir para Listagem
                      <v-icon right>mdi-table</v-icon>
                    </v-btn>

                    <v-btn
                        :block="blockBtns"
                        dark
                        depressed
                        color="var(--cor-primaria-100)"
                        @click="submit()"
                    >
                      {{ tipoSubmit === "cadastrar" ? "Registrar" : "Editar" }}
                      <v-icon right>
                        mdi-send-check
                      </v-icon>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </menu-principal>
</template>

<script>
import MenuPrincipal from "../../components/MenuPrincipal";
import TituloPagina from "../../components/TituloPagina";
import BlocoErro from "../../components/BlocoErro";
import mixinGeral from "../../mixin/geral";
import pacotesServices from "../../services/pacotes";
import helpers from "@/assets/js/helpers";

export default {
  name: "Formulario",
  mixins: [mixinGeral],
  components: {
    MenuPrincipal,
    TituloPagina,
    BlocoErro,
  },
  data() {
    return {
      pacoteEncontrado: true,
      idPacote: null,
      taxaSetup: null,
      taxasSetup: [],
      formulario: {
        id: null,
        nome: null,
        tipoPagamento: null,
        porcentagem: null,
        parcelasGarantia: null,
        parcelasReforma: null,
        quantidadeParcelas: 1,
        comissaoTaxaSetup: null,
        comissaoPacote: null,
        habilitado: null,
      },
      erros: {
        nome: null,
        tipoPagamento: null,
        porcentagem: null,
        parcelasGarantia: null,
        parcelasReforma: null,
        quantidadeParcelas: null,
        comissaoTaxaSetup: null,
        comissaoPacote: null,
        habilitado: null,
      },
    };
  },
  computed: {
    iconeErro() {
      return "mdi-alert-octagram";
    },
    tipoSubmit() {
      return this.$route.meta.submit;
    },
    tituloPagina() {
      if (this.tipoSubmit === "cadastrar") {
        return "Cadastrar Pacote";
      }
      return "Editar Pacote";
    },
    itensSelect() {
      return {
        habilitacao: [
          {
            valor: false,
            label: "Desabilitado",
          },
          {
            valor: true,
            label: "Habilitado",
          },
        ],
        tipoPagamento: [
          {
            valor: "dinheiro",
            label: "Dinheiro",
          },
          {
            valor: "cartao",
            label: "Cartão de Crédito",
          },
          {
            valor: "boleto",
            label: "Boleto Bancário",
          },
        ],
      };
    },
    habilitacaoSubmit() {
      return !this.loading && !this.notificacao.status;
    },
    blockBtns() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    permissaoPagina() {
      return this.permissaoMaster;
    },
    permissaoPacote() {
      return true;
      if (this.tipoSubmit !== "editar" || !this.idPacote) return true;

      const idsClientes = this.clientes
          .filter((cliente) => cliente.pacoteId === this.idPacote)
          .map((cliente) => cliente.id);

      const contratosQueUtilizam = this.contratos.filter((contrato) => idsClientes.includes(contrato.clienteId));

      if (contratosQueUtilizam.length === 0) return true;

      const filtroPagamentos =
          contratosQueUtilizam.filter((contrato) => {
            const statusPagamento = contrato.pagarmeStatus;
            return ["paid", "processing", "authorized", "waiting_payment", "active"].includes(statusPagamento);
          }).length > 0;

      const filtroContrato =
          contratosQueUtilizam.filter((contrato) => {
            return contrato.clicksignStatus === "enviado";
          }).length > 0;

      return !filtroPagamentos && !filtroContrato;
    },
    configMonetario() {
      return {
        decimal: ",",
        thousands: ".",
        precision: 2,
        masked: false,
      };
    },
  },
  methods: {
    async listarTaxasSetup() {
      if (!this.idPacote) return;
      const {data} = await pacotesServices.listarTaxasSetup(this.idPacote);
      this.taxasSetup = data;
    },
    async adicionarTaxaSetup() {
      if (!this.idPacote) {
        this.taxasSetup.push({valor: this.taxaSetup});
        this.taxaSetup = null;
        return;
      }
      const taxaSetup = this.taxasSetup.find((taxa) => String(taxa.valor) === String(this.taxaSetup));
      if (taxaSetup) return;
      await pacotesServices.adicionarTaxaSetup({valor: this.taxaSetup}, this.idPacote);
      this.taxaSetup = null;
      await this.listarTaxasSetup();
    },
    async removerTaxaSetup(taxa) {
      if (!this.idPacote){
        this.taxasSetup = this.taxasSetup.filter((t) => t.valor !== taxa.valor);
        return;
      }
      await pacotesServices.removerTaxaSetup(taxa);
      await this.listarTaxasSetup();
    },
    buscarPacote() {
      const id = this.$route.params.id;
      const pacote = this.pacotes.find((p) => Number(p.id) === Number(id));

      if (!pacote) {
        this.pacoteEncontrado = false;
      } else {
        this.idPacote = pacote.id;

        this.formulario.nome = pacote.nome;
        this.formulario.tipoPagamento = pacote.tipoPagamento;
        this.formulario.porcentagem = helpers.numericoParaMonetarioBrasil(pacote.porcentagem);
        this.formulario.quantidadeParcelas = pacote.quantidadeParcelas;
        this.formulario.parcelasGarantia = pacote.parcelasGarantia;
        this.formulario.parcelasReforma = pacote.parcelasReforma;
        this.formulario.comissaoTaxaSetup = helpers.numericoParaMonetarioBrasil(pacote.comissaoTaxaSetup);
        this.formulario.comissaoPacote = helpers.numericoParaMonetarioBrasil(pacote.comissaoPacote);
        this.formulario.habilitado = pacote.habilitado;
        this.taxasSetup = pacote.taxasSetup;
      }
    },
    confirmarSaida() {
      this.$confirm("Deseja ser direcionado para lista de Pacotes ?").then((res) => {
        if (res) {
          this.navegar("pacotesIndex");
        }
      })
    },
    submit() {
      // if (!this.habilitacaoSubmit) return;
      if (this.tipoSubmit === "cadastrar") return this.cadastrar();
      return this.atualizar();
    },
    async cadastrar() {
      this.erros = {};
      this.ativarLoading();
      try {
        const { data } = await pacotesServices.cadastrar(this.formulario);
        const taxas = this.taxasSetup.map((taxa) => {
          return pacotesServices.adicionarTaxaSetup(taxa, data.id);
        });
        await Promise.all(taxas);
        this.notificacaoSucesso("Pacote Cadastrado");
        this.navegar("pacotesIndex");
      } catch (e) {
        this.interpretarErro(e);
        if (this.obterCodigoRequisicao(e) === 422) {
          this.apresentarErros(e.response.data.erros);
        }
      } finally {
        this.desativarLoading();
      }
    },
    async atualizar() {
      this.erros = {};
      this.ativarLoading();
      try {
        await pacotesServices.editar(this.idPacote, this.formulario);
        this.notificacaoSucesso("Pacote Editado");
        this.navegar("pacotesIndex");
      } catch (e) {
        this.interpretarErro(e);
        if (this.obterCodigoRequisicao(e) === 422) {
          this.apresentarErros(e.response.data.erros);
        }
      } finally {
        this.desativarLoading();
      }
    },
    apresentarErros(erros) {
      if (!helpers.empty(erros.nome)) this.erros.nome = erros.nome[0];
      if (!helpers.empty(erros.tipo_pagamento)) this.erros.tipoPagamento = erros.tipo_pagamento[0];
      if (!helpers.empty(erros.porcentagem)) this.erros.porcentagem = erros.porcentagem[0];
      if (!helpers.empty(erros.quantidade_parcelas)) this.erros.quantidadeParcelas = erros.quantidade_parcelas[0];
      if (!helpers.empty(erros.parcelas_garantia)) this.erros.parcelasGarantia = erros.parcelas_garantia[0];
      if (!helpers.empty(erros.parcelas_reforma)) this.erros.parcelasReforma = erros.parcelas_reforma[0];
      if (!helpers.empty(erros.comissao_taxa_setup)) this.erros.comissaoTaxaSetup = erros.comissao_taxa_setup[0];
      if (!helpers.empty(erros.comissao_pacote)) this.erros.comissaoPacote = erros.comissao_pacote[0];
      if (!helpers.empty(erros.habilitado)) this.erros.habilitado = erros.habilitado[0];
    },
  },
  watch: {
    "formulario.tipoPagamento"(novoValor) {
      if (novoValor !== "cartao") {
        this.formulario.quantidadeParcelas = 1;
      }
    },
  },
  async created() {
    if (this.tipoSubmit === "editar") {
      this.buscarPacote();
    }
  },
};
</script>
